const { ethers } = require('ethers')
const BazaERC20Abi = require('@bazarion/protocol/abis/baza/BazaERC20.json')

class FT {
    constructor(ft, provider) {
        let signer = provider.getSigner()
        if (provider.connection.url !== 'metamask') {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
        }
        this.ft = new ethers.Contract(ft, BazaERC20Abi, signer)
        this.address = this.ft.address
    }

    symbol() {
        return this.ft.symbol()
    }

    decimals() {
        return this.ft.decimals()
    }

    name() {
        return this.ft.name()
    }

    balanceOf(addr) {
        return this.ft.balanceOf(addr)
    }

    async allowance(owner, addr) {
        return this.ft.allowance(owner, addr)
    }

    async approve(operator) {
        return this.ft.approve(operator, ethers.constants.MaxInt256)
    }

}

module.exports = FT

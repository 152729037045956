const constants = require('./constants')
const FTMarket = require('./ftMarket')
const FT = require('./ft')
const FTMarketMulticall = require('./ftMarketMulticall')
const FTFactory = require('./ftFactory')
const Referral = require('./referral')
const ReferralMulticall = require('./referralMulticall')
const FeeMiningPool = require('./feeMiningPool')
const FeeMiningPoolMulticall = require('./feeMiningPoolMulticall')
const contracts = require('./contracts')
const { ethers } = require('ethers')

const NETWORKS = constants.NETWORKS

function getProvider (chainId) {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16)))
    return new ethers.providers.StaticJsonRpcProvider(network.rpcUrls[0], { chainId: chainId })
}

function getWETH (chainId) {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16)))
    return network.nativeCurrency.wrappedToken
}

module.exports = {
    FT,
    FTMarket,
    FTFactory,
    constants,
    contracts,
    getProvider,
    getWETH,
    FTMarketMulticall,
    Referral,
    ReferralMulticall,
    FeeMiningPool,
    FeeMiningPoolMulticall
}

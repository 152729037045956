<template>
    <div class="make-offer">
        <b-card no-body class="box-bid-price mb-3">        
            <b-card-body>            
                <b-form autocomplete="off">
                    <b-form-group
                        label="Amount"
                        label-for="amount"
                        >
                        <b-form-input
                        id="deposit-amount"
                        v-model="lpAmount"
                        type="number"
                        placeholder="Enter amount"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Price"
                        label-for="price"
                        >
                        <b-form-input
                        id="price"
                        v-model="lpPrice"
                        type="number"
                        placeholder="Enter price"
                        required
                        ></b-form-input>
                        <b-card-text class="text-right balance">
                            <span>{{ (bidData.orderId) ? utils.bigToCommon(bidData.price.mul(bidData.amount), quoteDecimals) : parseFloat(lpAmount * parseFloat(lpPrice)).toFixed(4) }} {{ quoteSymbol }}</span>&nbsp;<b-icon-arrow-right></b-icon-arrow-right>&nbsp;<span>{{ lpAmount }} {{ baseSymbol }}</span>
                        </b-card-text>
                    </b-form-group>
                </b-form>

                <div v-if="!loadingData">
                <b-btn class="btn-custom btn-cancel mr-3" @click="$emit('p2p-bid-cancel')">
                    Cancel
                </b-btn>

                <b-button
                    class="btn-custom btn-bid"
                    :disabled='isDisabled'
                    v-if="!loadingData"
                    @click="bid()"
                    >
                    Submit
                    <b-spinner v-if="isDisabled" small label="Loading..."></b-spinner>
                </b-button>
                </div>
                <div v-else>
                    <b-skeleton type="button"></b-skeleton>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import { ethers } from 'ethers'
import { FT, FTMarket, FTMarketMulticall } from '@bazarion/sdk'

export default {
    name: 'P2PBid',
    data: () => ({
        quote: '',
        quoteDecimals: 18,
        quoteSymbol: '',
        baseSymbol: '',
        base: '',
        baseDecimals: 18,
        marketAddress: '',
        lpOrderId: ethers.BigNumber.from(0),
        lpPrice: 0,
        lpAmount: '0',
        isBaseNative: false,
        isQuoteNative: false,
        isDisabled: false,
        isQuoteApproved: false,
        market: {},
        loadingData: true
    }),
    props: ['bidData', 'quoteLink', 'baseLink'],
    created: async function () {
        this.market = await FTMarket.setMarket(this.baseLink, this.quoteLink, this.provider)
        let calls = await FTMarketMulticall.setMulticall(this.market.baseToken, this.market.quoteToken, this.provider)

        this.marketAddress = this.market.marketAddress
        this.quote = this.market.quoteToken
        this.base = this.market.baseToken
        this.isQuoteNative = this.market.isQuoteNative
        this.isBaseNative = this.market.isBaseNative
        this.address = await this.provider.getSigner().getAddress()

        let { quoteDecimals, quoteSymbol } = await calls.getQuoteInfo()
        this.quoteDecimals = quoteDecimals
        this.quoteSymbol = quoteSymbol
        let { baseSymbol, baseDecimals } = await calls.getBaseInfo()
        this.baseDecimals = baseDecimals
        this.baseSymbol = baseSymbol

        if (this.bidData.orderId) {
            this.lpPrice = ethers.utils.formatUnits(this.bidData.price, this.quoteDecimals)
            this.lpOrderId = this.bidData.orderId || ethers.BigNumber.from(0)
            this.lpAmount = this.bidData.amount.toNumber()
        }

        let quoteFT = new FT(this.quote, this.provider)
        this.isQuoteApproved = this.isQuoteNative || !(await quoteFT.allowance(this.address, this.marketAddress)).eq(0)

        this.loadingData = false
    }, 
    methods: {
        async quoteApprove() {
            let quoteFT = new FT(this.quote, this.provider)
            let tx = await quoteFT.approve(this.marketAddress)
            this.$bvToast.show('tx-confirm')
            await tx.wait()
            this.$bvToast.show('tx-success')
            this.$bvToast.hide('tx-confirm')
            this.isQuoteApproved = true
        },
        async bid () {
            try {
                this.isDisabled = true
                if (!this.isQuoteApproved) {
                    await this.quoteApprove()
                }
                let amount = ethers.utils.parseUnits(this.lpAmount, this.baseDecimals)
                let price = ethers.utils.parseUnits(this.lpPrice, this.quoteDecimals)
                let tx = await this.market.bid(this.lpOrderId, amount, price)
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.$emit('p2p-bid-success', {
                    txHash: tx.hash
                })
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.show('tx-error')
                this.isDisabled = false
            }
        }
    }
};
</script>

<style lang="scss">
    .make-offer{        
        .base-name{
            font-size: 30px;
            font-weight: 700;
        }
        .box-bid-price{
            background-color: #F4F5F7;
            border-radius: 8px;
            border: none;
            label{
                color:#9D22C1;
                text-transform: uppercase;
                font-size: 12px;
            }
            #price, #amount{
                height: 56px;
                border-radius: 8px;
                border: 1px solid #DFE1E6;
            }
            .balance{
                font-size: 14px;
                color: #5E6C84;
                font-weight: normal;
                margin-top: 10px;
                justify-content: end;
            }
        }
        .btn-custom{
            padding: 8px 16px;
            border: none;
            font-weight: 400;
            border-radius: 8px;
            &:focus{
                box-shadow: none;
            }         
        }

		.btn-bid {
			background: #36B37E;
			color: #FFF;
			border-radius: 30px;
			border-color: #36B37E;
			&:focus {
				box-shadow: none;
			}
			&:hover {
				box-shadow: none;
				color: #36B37E;
				background-color: #FFF;
			}
		}
        .btn-cancel{
            color: #196EC2;
			border-radius: 30px;
            background-color: rgba(25, 110, 194, 0.1);
            &:hover{
                background-color: rgba(25, 110, 194, 0.295);
                color: #196EC2;
            }
        }
    }
</style>

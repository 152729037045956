<template>
    <footer class="nft-footer is-desktop">
        <div class="d-flex nft-footer__content align-items-center justify-content-between">
            <div class="nft-footer__left">
                <ul class="nft-footer__list d-flex align-items-center">
                    <li class="nft-footer__item">
                        <div class="nft-footer__data" :class="(isActiveData) ? 'active-data' : 'inactive-data'">
                            Off-chain Data {{ (isActiveData) ? 'Active' : 'Inactive' }}
                        </div>
                    </li>
                    <!--li class="nft-footer__item">
                        <div class="d-flex">
                           <b-link class="mr-2 social-link"><b-img  v-bind:src="ethIcon" alt="social icon" width="12px"></b-img></b-link>
                            <b-link class="mr-2 social-link"><b-img  v-bind:src="ethIcon" alt="social icon" width="12px"></b-img></b-link>
                            <b-link class="mr-2 social-link"><b-img  v-bind:src="ethIcon" alt="social icon" width="12px"></b-img></b-link>
                            <b-link class="social-link"><b-img  v-bind:src="ethIcon" alt="social icon" width="12px"></b-img></b-link>
                        </div>
                    </li-->
                    <!--li class="nft-footer__item">
                        <div class="d-flex">
                            <b-link class="mr-2 social-link"><b-img  v-bind:src="discordIcon" alt="social icon" width="12px"></b-img></b-link>
                            <b-link class="social-link"><b-img  v-bind:src="twitterIcon" alt="social icon" width="12px"></b-img></b-link>
                        </div>
                    </li-->
                    <!--li class="nft-footer__item">
                        <div class="d-flex">
                            <span class="social-link color-text nft-footer__price"><b-img  v-bind:src="ethIcon" alt="social icon" class="mr-2" width="12px"></b-img>117.0583</span>
                        </div>
                    </li-->
                </ul>
            </div>
            <div class="nft-footer__right">
                <ul class="nft-footer__list d-flex align-items-center color-text">
                    <li class="nft-footer__item">
                        <div class="d-flex">
                            <span class="social-link color-text nft-footer__price"><b-img  v-bind:src="gwei" alt="social icon" class="mr-2" width="12px"></b-img>{{ gasPrice }} gwei</span>
                        </div>
                    </li>
                    <!--li class="nft-footer__item">
                        <div class="d-flex">
                            <span class="social-link nft-footer__price color-orange"><b-img  v-bind:src="gasIcon" alt="social icon" class="mr-2" width="12px"></b-img>Gas Priority 2 / Max Fee 38</span>
                        </div>
                    </li>
                    <li class="nft-footer__item">
                        <div class="d-flex">
                            <span class="social-link color-text nft-footer__price"><b-img  v-bind:src="number1" alt="social icon" class="mr-2" width="10px"></b-img>50</span>
                        </div>
                    </li>
                    <li class="nft-footer__item">
                        <div class="d-flex">
                            <span class="social-link color-text nft-footer__price"><b-img  v-bind:src="number2" alt="social icon" class="mr-2" width="10px"></b-img>100</span>
                        </div>
                    </li>
                    <li class="nft-footer__item">
                        <div class="d-flex">
                            <span class="social-link color-text nft-footer__price"><b-img  v-bind:src="number3" alt="social icon" class="mr-2" width="10px"></b-img>100</span>
                        </div>
                    </li-->
                
                </ul>
            </div>
        </div>
    </footer>
</template>
<script>

import ethIcon from '../assets/images/eth.png'
import discordIcon from '../assets/images/discord.svg'
import twitterIcon from '../assets/images/twitterIcon.svg'
import gwei from '../assets/images/gwei.svg'
import gasIcon from '../assets/images/gasIcon.svg'
import number1 from '../assets/images/number1.svg'
import number2 from '../assets/images/number2.svg'
import number3 from '../assets/images/number3.svg'
import { ethers } from 'ethers'

export default {
    data: () => ({
        ethIcon,
        discordIcon,
        twitterIcon,
        gwei,
        gasIcon,
        number1,
        number2,
        number3,
        gasPrice: 0
    }),
    props: [ 'isActiveData' ],
    created: async function () {
        try {
            this.gasPrice = ethers.utils.formatUnits(await this.provider.getGasPrice(), 'gwei')
        } catch(e) {
            // eslint-disable-next-line
        }
    }

}
</script>
<style lang="scss">
.nft-footer {
    background-color: #F4F5F7;
    padding: 4px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        list-style-type: none;
    }

    &__item {
        padding: 0 16px;
        border-right: 1px solid #DFE1E6;

        &:last-child {
            border-right: 0;
            padding-right: 8px;
        }
        &:first-child {
            padding-left: 8px
        }
    }

    .inactive-data{
        color: #DE350B !important;
        &::before {
            background-color: #DE350B !important
        }
    }

    &__data {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #36B37E;
        &::before {
            content: '';
            height: 8px;
            width: 8px;
            display: block;
            border-radius: 100%;
            overflow: hidden;
            background-color: #36B37E;
            margin-right: 4px;
        }
    }

    &__price {
        color: #091E42;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }
}
   
</style>

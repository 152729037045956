const { ethers } = require('ethers')
const FTMarketAbi = require('@bazarion/protocol/abis/baza/FTMarket.json')
const FTFactoryAbi = require('@bazarion/protocol/abis/baza/FTFactory.json')
const BazaERC20Abi = require('@bazarion/protocol/abis/baza/BazaERC20.json')
const { Contract, Provider } = require('ethcall')
const contracts = require('./contracts')
const { NETWORKS, MULTICALLS } = require('./constants')

class FTMarketMulticall {
    constructor(baseToken, quoteToken, market, chainId, provider, ethcallProvider) {
        this.baseContract = new Contract(baseToken, BazaERC20Abi)
        this.quoteContract = new Contract(quoteToken, BazaERC20Abi)
        this.marketContract = new Contract(market, FTMarketAbi)
        this.provider = provider
        this.ethcallProvider = ethcallProvider
        this.chainId = chainId
        this.network = NETWORKS.find(n => (n.chainId === '0x' + this.chainId.toString(16)))
        this.isQuoteNative = (this.quoteContract.address === this.network.nativeCurrency.wrappedToken)
        this.isBaseNative = (this.baseContract.address === this.network.nativeCurrency.wrappedToken)
    }

    static setMulticall(baseToken, quoteToken, provider) {
        const ethcallProvider = new Provider()
        return ethcallProvider.init(provider).then(() => {
            return provider.getNetwork().then(net => {
                const multi = MULTICALLS.find(n => (n.chainId === '0x' + net.chainId.toString(16)))
                const contract = contracts.find(c => (c.ChainId === net.chainId))
                ethcallProvider.multicall = { address: multi.address, block: 0 }

                let signer = provider.getSigner()
                if (provider.connection.url !== 'metamask') {
                    let randomWallet = ethers.Wallet.createRandom()
                    signer = new ethers.Wallet(randomWallet.privateKey, provider)
                }

                return signer.getAddress().then(() => {
                    let bazaFactory = new ethers.Contract(contract.FTFactory, FTFactoryAbi, signer)
                    return bazaFactory.getMarket(baseToken, quoteToken).then((market) => {
                        return new FTMarketMulticall(baseToken, quoteToken, market, net.chainId, provider, ethcallProvider)
                    })
                }).catch(() => {
                    let randomWallet = ethers.Wallet.createRandom()
                    signer = new ethers.Wallet(randomWallet.privateKey, provider)
                    let ftFactory = new ethers.Contract(contract.FTFactory, FTFactoryAbi, signer)
                    return ftFactory.getMarket(baseToken, quoteToken).then((market) => {
                        return new FTMarketMulticall(baseToken, quoteToken, market, net.chainId, provider, ethcallProvider)
                    })
                })
            })
        })
    }

    async askOfOwnerByIndex(asker, from, to) {
        let calls = []
        for (let i = from; i < to; i++) {
            calls.push(this.marketContract.askOfOwnerByIndex(asker, i))
        }
        let data = await this.ethcallProvider.all(calls)
        return data
    }

    async bidOfOwnerByIndex(bidder, from, to) {
        let calls = []
        for (let i = from; i < to; i++) {
            calls.push(this.marketContract.bidOfOwnerByIndex(bidder, i))
        }
        let data = await this.ethcallProvider.all(calls)
        return data
    }

    async getBasicTokenInfo() {
        let data = await this.ethcallProvider.all([
            this.baseContract.name(),
            this.baseContract.symbol(),
            this.baseContract.decimals(),
            this.quoteContract.name(),
            this.quoteContract.symbol(),
            this.quoteContract.decimals()
        ])
        let baseName = data[0]
        let baseSymbol = data[1]
        let baseDecimals = data[2]
        let quoteName = data[3]
        let quoteSymbol = data[4]
        let quoteDecimals = data[5]
        return { baseName, baseSymbol, baseDecimals, quoteName, quoteSymbol, quoteDecimals }
    }

    async getMarketInfo() {
        let data = await this.ethcallProvider.all([
            this.baseContract.totalSupply(),
            this.quoteContract.totalSupply(),
            this.baseContract.balanceOf(this.marketContract.address),
            this.quoteContract.balanceOf(this.marketContract.address),
            this.baseContract.decimals(),
            this.quoteContract.decimals(),
            this.baseContract.symbol(),
            this.quoteContract.symbol(),
            this.baseContract.name(),
            this.quoteContract.name(),
            this.marketContract.getBestBid(),
            this.marketContract.getBestAsk(),
            this.marketContract.getLatestPrice(),
            this.marketContract.EXCHANGE_FEE(),
        ])
        let baseSupply = data[0]
        let quoteSupply = data[1]
        let baseTvl = data[2]
        let quoteTvl = data[3]
        let baseDecimals = data[4]
        let quoteDecimals = data[5]
        let baseSymbol = data[6]
        let quoteSymbol = data[7]
        let baseName = data[8]
        let quoteName = data[9]
        let bestBid = data[10]
        let bestAsk = data[11]
        let latestPrice = data[12]
        let exchangeFee = data[13]
        const network = NETWORKS.find(n => (n.chainId === '0x' + this.chainId.toString(16)))
        if (this.quoteContract.address === network.nativeCurrency.wrappedToken) {
            quoteSymbol =  network.nativeCurrency.symbol
        }
        if (this.baseContract.address === network.nativeCurrency.wrappedToken) {
            baseSymbol =  network.nativeCurrency.symbol
        }
        return { baseSupply, quoteSupply, baseTvl, quoteTvl, baseDecimals, quoteDecimals, baseSymbol,
            quoteSymbol, baseName, quoteName, bestBid, bestAsk, latestPrice, exchangeFee }
    }

    async getQuoteInfo() {
        let data = await this.ethcallProvider.all([
            this.quoteContract.decimals(),
            this.quoteContract.name(),
            this.quoteContract.symbol()
        ])
        let quoteDecimals = data[0]
        let quoteName = data[1]
        let quoteSymbol = (this.isQuoteNative)
            ? this.network.nativeCurrency.symbol
            : data[2]
        let balance = (!this.isQuoteNative)
            ? (await this.quoteContract.balanceOf(this.address))
            : (await this.provider.getSigner().getBalance())
        return { quoteDecimals, quoteName, quoteSymbol, balance }
    }
    async getBaseInfo() {
        let data = await this.ethcallProvider.all([
            this.baseContract.decimals(),
            this.baseContract.name(),
            this.baseContract.symbol()
        ])
        let baseDecimals = data[0]
        let baseName = data[1]
        let baseSymbol = (this.isBaseNative)
            ? this.network.nativeCurrency.symbol
            : data[2]
        let balance = (!this.isBaseNative)
            ? (await this.baseContract.balanceOf(this.address))
            : (await this.provider.getSigner().getBalance())
        return { baseDecimals, baseName, baseSymbol, balance }
    }

    async getBidOrderInfo(orderIds) {
        let calls = orderIds.map(orderId => {
            return this.marketContract.getBidByOrderId(orderId)
        })

        return this.ethcallProvider.all(calls)
    }

    async getAskOrderInfo(orderIds) {
        let calls = orderIds.map(orderId => {
            return this.marketContract.getAskByOrderId(orderId)
        })

        return this.ethcallProvider.all(calls)
    }

}

module.exports = FTMarketMulticall

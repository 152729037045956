const { ethers } = require('ethers')
const FTFactoryAbi = require('@bazarion/protocol/abis/baza/FTFactory.json')
const contracts = require('./contracts')

class Factory {
    constructor(contract, provider, signer = null) {
        if (!signer) {
            signer = provider.getSigner()
        }
        this.factory = new ethers.Contract(contract.FTFactory, FTFactoryAbi, signer)
    }

    static async setProvider(provider) {
        let { chainId } = await provider.getNetwork()
        let contract = contracts.find(c => (c.ChainId === chainId))

        let signer = provider.getSigner()
        if (provider.connection.url !== 'metamask') {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
        }

        return signer.getAddress().then(() => {
            return new Factory(contract, provider, signer)
        }).catch(() => {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
            return new Factory(contract, provider, signer)
        })
    }

    async createMarket(baseToken, quoteToken) {
        return this.factory.createMarket(baseToken, quoteToken)
    }

    async createMarketWait(baseToken, quoteToken) {
        return (await this.factory.createMarket(baseToken, quoteToken)).wait()
    }

    async getMarket(baseToken, quoteToken) {
        return this.factory.getMarket(baseToken, quoteToken)
    }

    async allMarketsLength() {
        return this.factory.allMarketsLength()
    }

    async allMarkets(i) {
        return this.factory.allMarkets(i)
    }

    async getAllMarkets() {
        return this.factory.getAllMarkets()
    }
}

module.exports = Factory

<template>
    <b-container class="homepage">
        <div class="box-wrap mb-4">
            <b-row class="is-multiline">
                <b-col cols="6">
                    <b-card class="token-box" no-body style="cursor: pointer" @click="showSelectBase()">
                        <div class="avatar">
                            <b-card-img :src="baseLogo || require('../assets/images/nologo.svg')" alt="baseImage"></b-card-img>
                        </div>
                        <b-card-body>
                            <div class="token-info">
                                <b-card-text class="token-name" text-tag="span" >{{ utils.truncate(baseSymbol, 7) }}</b-card-text>
                                <b-icon icon="caret-down-fill"></b-icon>
                            </div>
                            <b-card-sub-title>{{ baseName }}</b-card-sub-title>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col cols="6">
                    <b-card class="token-box" no-body style="cursor: pointer" @click="showSelectQuote()" img-width="60">
                        <div class="avatar icon-native">
                            <b-card-img :src="quoteLogo || require('../assets/images/nologo.svg')" alt="quoteImage"></b-card-img>
                        </div>
                        <b-card-body>
                            <div class="token-info">
                                <b-card-text class="token-name" text-tag="span" >{{ utils.truncate(quoteSymbol, 7) }}</b-card-text>
                                <b-icon icon="caret-down-fill"></b-icon>
                            </div>
                            <b-card-sub-title>{{ quoteName }}</b-card-sub-title>                            
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-card class="icon-arrow" no-body style="cursor: pointer">
                    <b-card-text>
                        <b-icon icon="arrow-left-right" font-scale="1.5"></b-icon>
                    </b-card-text>
                </b-card>
			</b-row>
            <b-row class="is-multiline mt-4" cols="2" cols-sm="2" cols-md="3" no-gutters>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size" >{{ baseSymbol }} Supply</b-card-text>
                        <b-card-text v-if="!loadingData"> {{ utils.bigToLabel(baseSupply, baseDecimals, 0) }} </b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size"> TVL </b-card-text> 
                        <b-card-text v-if="!loadingData"> {{ utils.bigToLabel(baseTvl, baseDecimals, 2) }} {{ baseSymbol }}</b-card-text> 
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size" >Best Ask</b-card-text>
                        <b-card-text v-if="!loadingData" >{{ utils.bigToLabel(bestAskPrice, quoteDecimals, 2) }} {{ quoteSymbol }}</b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size"> Best Bid </b-card-text> 
                        <b-card-text v-if="!loadingData" >{{ utils.bigToLabel(bestBidPrice, quoteDecimals, 2) }} {{ quoteSymbol }}</b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size"> Latest Price </b-card-text> 
                        <b-card-text v-if="!loadingData"> {{ utils.bigToLabel(latestPrice, quoteDecimals, 2) }} {{ quoteSymbol }}</b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
                <b-col class="px-1">
                    <b-card class="card-item mb-2 py-3 px-2" align="center" no-body>
                        <b-card-text class="mb-0 t-size">Exchange Fee</b-card-text> 
                        <b-card-text v-if="!loadingData">{{ utils.feeToCommon(exchangeFee) }}%</b-card-text>
                        <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="is-multiline">
                <b-col class="mt-3">
                    <router-link custom v-slot="{ navigate }" :to="{ name: 'fttrade', params: { base: baseLink, quote: quoteLink, chainId: chainId }}" style="cursor: pointer" v-if="!isCreate">
                        <b-button class="button btn-go-market" @click="navigate" @keypress.enter="navigate">Go to {{ baseSymbol }}-{{ quoteSymbol }} Market</b-button>
                    </router-link>
                    <b-button class="button btn-wallet-connect" style="width: 100%" v-if="isConnect && isCreate" @click="connect()">Connect to a wallet</b-button>
                    <b-button class="button btn-create-market" :disabled="isCreateDisabled" style="width: 100%; background-color: #196EC2" v-if="isCreate && !isConnect" @click="isCreateDisabled = true;create()">Create {{ baseSymbol }}-{{ quoteSymbol }} Market</b-button>
                </b-col>
            </b-row>
            <!--b-row>
                <b-col class="small mt-4 text-center" v-if="!isCreate">
                    <router-link custom :to="{ name: 'marketInfo', params: { ft: ftLink, nft: nftToken, chainId: chainId }}" style="cursor: pointer">
                        <u><b-icon-pie-chart></b-icon-pie-chart> Market Info</u>
                    </router-link> 
                </b-col>
                <b-col class="small mt-4 text-center">
                    <router-link custom :to="{ name: 'listMarket', params: { chainId: chainId }}" style="cursor: pointer">
                        <u><b-icon-book></b-icon-book> All Markets</u>
                    </router-link>
                </b-col>
            </b-row-->
		</div>
        <div>
            <b-modal id="select-base" no-close-on-backdrop centered hide-footer @hidden="watchSearchBaseToken = ''" title="Select a collection">
                <div style="min-height: 200px" >
                    <b-form-group
                     label-for="search-base-token"
                     style="position: relative"
                     >
                     <b-form-input
                     id="search-base-token"
                     class="search-token"
                     type="text"
                     placeholder="Paste an address"
                     v-model.lazy="watchSearchBaseToken"
                     required
                     ></b-form-input>
                     <b-icon class="icon-search" icon="search"></b-icon>
                    </b-form-group>

                    <b-container v-for="(token, key) in baseTokens" :key="token.address">
                        <b-row no-gutter justify-content="space-between" align-items="center" class="mb-3">
                            <b-col class="is-flex px-0" style="align-items: center;"> 
                                <b-img class="token-icon mr-2" :src="token.logoURI || require('../assets/images/nologo.svg')"></b-img>                                
                                <div>
                                    <b-card-title class="token-symbol">
                                        {{ token.symbol }}
                                    </b-card-title>
                                    <b-card-text class="token-name">{{ token.name }}</b-card-text>
                                    <b-card-text class="token-remove" v-if="token.imported" @click="removeToken(token)">Remove</b-card-text>
                                </div>
                            </b-col>
                            <b-col><b-card-text v-if="token.balance">{{ utils.bigToCommon(token.balance, token.decimals, 6) }}</b-card-text></b-col>
                            <div><b-button @click="importBase(key)" class="token-import">Import</b-button></div>
                        </b-row>
                    </b-container>
                </div>
            </b-modal>
        </div>
        <div>
            <b-modal id="select-quote" no-close-on-backdrop centered hide-footer @hidden="watchSearchQuoteToken = ''" title="Select a token">
                <div style="min-height: 200px" >
                    <b-form-group
                     label-for="search-ft-token"
                     style="position: relative"
                     >
                     <b-form-input
                     id="search-ft-token"
                     class="search-token"
                     type="text"
                     v-model.lazy="watchSearchQuoteToken"
                     placeholder="Paste an address"
                     required
                     ></b-form-input>
                     <b-icon class="icon-search" icon="search"></b-icon>
                    </b-form-group>
                    <b-container v-for="(token, key) in quoteTokens" :key="token.symbol">
                        <b-row no-gutter justify-content="space-between" align-items="center" class="mb-3">
                            <b-col class="is-flex px-0" style="align-items: center;">
                                <b-img class="token-icon mr-2" :src="token.logoURI || require('../assets/images/nologo.svg')"></b-img>  
                                <div>
                                    <b-card-title class="token-symbol">{{ token.symbol }}</b-card-title>
                                    <b-card-text class="token-name">{{ token.name }}</b-card-text>
                                    <b-card-text class="token-remove" v-if="token.imported" @click="removeToken(token)">Remove</b-card-text>
                                </div>
                            </b-col>
                            <b-col><b-card-text  v-if="token.balance">{{ utils.bigToCommon(token.balance, token.decimals, 6) }}</b-card-text ></b-col>
                            <div><b-button @click="importQuote(key)" class="token-import">Import</b-button></div>
                        </b-row>
                    </b-container>
                </div>
            </b-modal>
            <b-modal id="import-token-alert" no-close-on-backdrop centered hide-footer title="Import tokens">
                <ImportTokenAlert @import-success="$bvModal.hide('import-token-alert')" :tokens="{ base: baseToken, quote: quoteToken }"/>
            </b-modal>

            <b-modal id="tx-result" no-close-on-backdrop centered title="Transaction Confirmed">
                <strong>Congratulations!</strong> <br />
                You successfully created pair <router-link :to="{ name: 'fttrade', params: { base: baseLink, quote: quoteLink, chainId: chainId }}" style="cursor: pointer"><strong>{{ baseSymbol }}/{{ quoteSymbol}}</strong></router-link> on Bazarion <br />
                You can check out your recent activity <b-link target="_blank" :href="utils.txLink(chainId, txHash)">here</b-link>. <br />
                <template #modal-footer="{ ok }">
                    <b-button variant="success" @click="ok()">
                        OK
                    </b-button>
                </template>
            </b-modal>
        </div>

        <b-toast id="tx-confirm" toaster="b-toaster-top-center" title="Transaction Process" variant="info" solid no-auto-hide>
            Your transaction is being processed.
        </b-toast>
        <b-toast id="tx-success" toaster="b-toaster-top-center" title="Transaction Success" variant="success" solid auto-hide-delay="8000">
            Your transaction is successful.
        </b-toast>

    </b-container>
</template>
<script>
import { ethers } from 'ethers'
import { FTFactory, FT, FTMarketMulticall } from '@bazarion/sdk'
import EventBus from '../eventBus'
import store from '../stores'
import ImportTokenAlert from './ImportTokenAlert.vue'

export default {
    components: {
        ImportTokenAlert
    },
    data: () => ({
        watchSearchBaseToken: '',
        watchSearchQuoteToken: '',
        baseToken: '',
        baseLink: '',
        quoteToken: '',
        quoteLink: '',
        quoteSymbol: '',
        baseLogo: '',
        baseSymbol: '',
        baseName: 'BAZAPET',
        quoteName: 'USDT',
        quoteDecimals: 18,
        baseDecimals: 18,
        quoteLogo: '',
        sBaseToken: '',
        sQuoteToken: '',
        market: {},
        baseTokens: [],
        quoteTokens: [],
        isCreate: true,
        baseSupply: ethers.BigNumber.from(0),
        quoteSupply: ethers.BigNumber.from(0),
        exchangeFee: 0,
        latestPrice: 0,
        bestBidPrice: ethers.BigNumber.from(0),
        bestAskPrice: ethers.BigNumber.from(0),
        floorPrice: ethers.BigNumber.from(0),
        baseTvl: ethers.BigNumber.from(0),
        quoteTvl: ethers.BigNumber.from(0),
        isConnect: true,
        isCreateDisabled: false,
        loadingData: true,
        isPixelated: false,
        txHash: ''
    }),
    updated: async function () { },
    created: async function () {
        try {
            this.provider = await this.getProvider(parseInt(this.$route.params.chainId))

            this.isConnect = (this.provider.connection.url !== 'metamask')
            this.address = await this.provider.getSigner().getAddress()
        } catch(e) {
            this.isConnect = true
        }
        this.factory = await FTFactory.setProvider(this.provider)
        let tokens = store.getTokens()
        this.baseTokens = tokens.filter(t => (t.isQuote === false && t.chainId === this.chainId))
        this.quoteTokens = tokens.filter(t => (t.isQuote === true && t.chainId === this.chainId))
        if (this.baseTokens.length > 0) {
            this.importBase(0)
        }
        if (this.quoteTokens.length > 0) {
            this.importQuote(0)
        }
        this.loadingData = false
    },
    watch: {
        async watchSearchBaseToken (sBaseToken) {
            let tokens = store.getTokens()
            let isAddress = false
            try {
                isAddress = !!ethers.utils.getAddress(sBaseToken)
            } catch (e) {
                isAddress = false
            }

            this.baseTokens = tokens.filter(t => {
                let b = (t.isQuote === false && t.chainId === this.chainId)
                return b && (t.symbol.toLowerCase().includes(sBaseToken.toLowerCase())
                    || t.name.toLowerCase().includes(sBaseToken.toLowerCase())
                    || t.address.toLowerCase().includes(sBaseToken.toLowerCase())
                )
            })

            if (isAddress) {
                let baseToken = new FT(sBaseToken, this.provider)
                let symbol = await baseToken.symbol()
                let name = await baseToken.name()
                this.baseTokens = [{ name, symbol, address: sBaseToken }]
            }
        },
        async watchSearchQuoteToken (sQuoteToken) {
            let tokens = store.getTokens()
            let isAddress = false
            try {
                isAddress = ethers.utils.getAddress(sQuoteToken)
            } catch (e) {
                isAddress = false
            }

            this.quoteTokens = tokens.filter(t => {
                let b = (t.isQuote === true && t.chainId === this.chainId)
                return b && (t.symbol.toLowerCase().includes(sQuoteToken.toLowerCase())
                    || t.name.toLowerCase().includes(sQuoteToken.toLowerCase())
                    || t.address.toLowerCase().includes(sQuoteToken.toLowerCase())
                )
            })

            if (isAddress) {
                let quoteToken = new FT(sQuoteToken, this.provider)
                let symbol = await quoteToken.symbol()
                let name = await quoteToken.name()
                this.quoteTokens = [{ name, symbol, address: sQuoteToken }]
            }
        },
    },
    methods: {
        async create () {
            try {
                let tx = await this.factory.createMarket(this.baseToken, this.quoteToken)
                this.$bvToast.show('tx-confirm')
                await tx.wait()
                this.txHash = tx.hash
                this.$bvModal.show('tx-result')
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                this.isCreate = false
            } catch(e) {
                this.isCreateDisabled = false
            }
        },
        async getMarketInfo() {
            if (!this.baseToken || !this.quoteToken) return
            this.market = await this.factory.getMarket(this.baseToken, this.quoteToken)
            this.isCreate = ethers.constants.AddressZero === this.market
            if (!this.isCreate) {
                let calls = await FTMarketMulticall.setMulticall(this.baseToken, this.quoteToken, this.provider)
                let { baseSupply, quoteSupply, baseTvl, quoteTvl, baseDecimals, quoteDecimals,
                    bestBid, bestAsk, latestPrice, exchangeFee } = await calls.getMarketInfo()
                this.baseSupply = baseSupply
                this.quoteSupply = quoteSupply
                this.bestBidPrice = bestBid.price
                this.bestAskPrice = bestAsk.price
                this.baseTvl = baseTvl
                this.quoteTvl = quoteTvl
                this.baseDecimals = baseDecimals
                this.quoteDecimals = quoteDecimals
                this.latestPrice = latestPrice
                this.exchangeFee = exchangeFee
            }
        },
        async importBase (i) {
            this.loadingData = true
            this.baseToken = this.baseTokens[i].address
            this.baseSymbol = this.baseTokens[i].symbol
            this.baseName = this.baseTokens[i].name
            this.baseLogo = this.baseTokens[i].logoURI
            this.baseLink = this.baseToken
            if (this.baseToken === this.network.nativeCurrency.wrappedToken) {
                this.baseLink = this.network.nativeCurrency.symbol
            }

            this.$bvModal.hide('select-base')
            let b = this.utils.checkFTTokenAlert(this.chainId, this.baseToken)
            if (b) {
                this.$bvModal.show('import-token-alert')
            }
            let tokens = store.getTokens()
            this.baseTokens = tokens.filter(t => (t.isQuote === false && t.chainId === this.chainId))
            this.sBaseToken = ''
            await this.getMarketInfo()
            this.loadingData = false
        },
        async importQuote (i) {
            this.loadingData = true
            this.quoteToken = this.quoteTokens[i].address
            this.quoteSymbol = this.quoteTokens[i].symbol
            this.quoteName = this.quoteTokens[i].name
            this.quoteLogo = this.quoteTokens[i].logoURI
            this.quoteLink = this.quoteToken
            if (this.quoteToken === this.network.nativeCurrency.wrappedToken) {
                this.quoteLink = this.network.nativeCurrency.symbol
            }

            this.$bvModal.hide('select-base')
            let b = this.utils.checkFTTokenAlert(this.chainId, this.quoteToken)
            if (b) {
                this.$bvModal.show('import-token-alert')
            }
            let tokens = store.getTokens()
            this.quoteTokens = tokens.filter(t => (t.isQuote === true && t.chainId === this.chainId))
            this.sQuoteToken = ''
            await this.getMarketInfo()
            this.loadingData = false
        },
        showSelectQuote () {
            let tokens = store.getTokens()
            this.quoteTokens = tokens.filter(t => (t.isQuote === true && t.chainId === this.chainId))
            this.$bvModal.show('select-quote')
        },
        showSelectBase () {
            let tokens = store.getTokens()
            this.baseTokens = tokens.filter(t => (t.isQuote === false && t.chainId === this.chainId))
            this.$bvModal.show('select-base')
        },
        connect () {
            EventBus.$emit('connect')
        },
        removeToken (token) {
            let tokens = store.removeToken(token)
            this.baseTokens = tokens.filter(t => (t.isQuote === false && t.chainId === this.chainId))
            this.quoteTokens = tokens.filter(t => (t.isQuote === true && t.chainId === this.chainId))
        }
    }


};
</script>

<style lang="scss">
    .container.homepage{
        width: 100%;
        max-width: 500px;
        margin-top: 40px;
    }
    .alert-old-version{
        border-radius: 16px !important;
        background-color: white !important;
        border-color: white !important;
    }
    .box-wrap{
        background-color: #fff;
        padding: 24px;
        border-radius: 16px;
        .is-multiline{
            position: relative;
        }        
        .icon-arrow{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            z-index: 1;
            background-color: #F4F5F7;
            border-color: #fff;
            border-width: 4px;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
        }
        .t-size{
            font-size:  12px;
            color: #7A869A;
        }
        .token-box{
            background-color: #F4F5F7;
            border: none;
            padding: 16px;
            border-radius: 8px;
            @media(max-width: 499px){
                .card-body{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .token-info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .token-name{
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 5px;
            }
        }
        .avatar{
            width: 100px;
            height: 100px;
            border-radius: 100%;
            position: relative;
            padding: 5px;
            margin: 0 auto;
            overflow: hidden;
            img{
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
        .card-item {
            border-radius: 8px;
        }
        .button{
            padding: 11px;
            border-radius: 8px;
            border: none;
            &.btn-go-market,&.btn-create-market{
                background-color:#9D22C1;
                width: 100%;
                &:hover{
                    background-color: #b62adf;
                }
            }
            &.btn-go-info{
                background-color:#9D22C1;
                &:hover{
                    background-color: #b62adf;
                }
            }
            &.btn-wallet-connect{
                background: #DE350B;
                &:hover{
                    background-color: #e53b11;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            
        }

    }
    #p2p-bid,#p2p-ask,#buy-now,#sell-now,#select-quote,#select-base{
        .modal-content{
            border-radius: 16px;
            overflow: hidden;
        }
        .modal-header{
            background
            : linear-gradient(243.18deg, #f4d4fd 0%, #ffffff 100%);
            justify-content: center;
            .modal-title{
                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }
        }
        .close{
            position: absolute;
            right: 20px;
            top: 15px;
        }
    }
    #select-quote,#select-base{        
        .token-icon{
            width: 40px;
            border-radius: 100%;

        }        
        .search-token{
            height: 56px;
            padding-left: 40px;
            margin-bottom: 30px;
            border-radius: 8px;
        }
        .icon-search{
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
        }
        .token-symbol{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0;
        }
        .token-name{
            color: #7A869A;
            font-size: 12px;
            margin-bottom: 0;
        }
        .token-remove{
            color: #9D22C1;
            font-size: 12px;
            margin-bottom: 0;
            cursor: pointer;
        }
        .token-import{
            background-color: rgba(25, 110, 194, 0.1);
            border: none;
            color: #196EC2;
            font-weight: 700;
            border-radius: 8px;
        }
    }    
    
</style>

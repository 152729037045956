<template>
    <div class="ft-trade-wrap">
        <b-container>
            <div class="c-header">
                    <div class="collection-name">
                        <div class="box-name i-flex">
                            <b-img v-bind:src="((items.length > 0) ? items[0].image : 0) || require('../../assets/images/nologo.svg')" alt="Base Image" class="base-image c-icon"></b-img>
                            <div>
                                <p v-if="!loadingData" class="name d-flex align-items-center fs-16 mb-lg-1"><span class="color-text">{{ baseSymbol }}/{{ quoteSymbol }}</span>
                                <!--b-img  v-bind:src="starIcon" alt="star icon" class="c-icon ml-1"></b-img--><span class="text-muted" size="sm">&nbsp;{{ baseName }}</span></p>
                            </div>

                        </div>
                    </div>
                    <div class="collection-info">
                        <div class="box-info is-flex">
                            <b-card class="card-item py-lg-3 pt-2 mt-1 mt-lg-0 pr-2 pl-0 px-lg-2" align="center" no-body>
                                <b-card-text class="mb-0 t-size">{{ quoteSymbol }} TVL </b-card-text>
                                <b-card-text v-if="!loadingData" class="i-flex s-gap">{{ utils.bigToLabel(quoteTvl, quoteDecimals, 2) }}</b-card-text>
                                <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                            </b-card>
                            <b-card class="card-item py-lg-3 pt-2 mt-1 mt-lg-0 pr-2 pl-0 px-lg-2" align="center" no-body>
                                <b-card-text class="mb-0 t-size">{{ baseSymbol }} TVL </b-card-text>
                                <b-card-text v-if="!loadingData" class="i-flex s-gap">{{ utils.bigToLabel(baseTvl, baseDecimals, 2) }}</b-card-text>
                                <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                            </b-card>
                            <b-card class="card-item py-lg-3 pt-2 mt-1 mt-lg-0 pr-2 pl-0 px-lg-2" align="center" no-body>
                                <b-card-text class="mb-0 t-size">Latest Price </b-card-text>
                                <b-card-text v-if="!loadingData" class="i-flex s-gap">{{ (latestPrice) ? utils.bigToLabel(latestPrice, quoteDecimals, 4) : '--'}}</b-card-text>
                                <b-card-text v-else><b-skeleton></b-skeleton></b-card-text>
                            </b-card>
                        </div>
                    </div>
            </div>
            <div class="mb-3">
                <div class="collection-content">
                    <div class="content-right">
                        <b-tabs content-class="mt-2" lazy>
                            <b-tab title="P2P-ORDERS">
                                <b-table class="tbl-ask-order table-order" borderless :fields="askFields" :items="asks" responsive  sticky-header="300px">
                                    <template #cell(price)="data">
                                        <span>{{ utils.bigToCommon(data.item.price, quoteDecimals, 4) }}</span>
                                    </template>
                                    <template #cell(amount)="data">
                                        <span>{{ utils.bigToCommon(data.item.amount, baseDecimals, 2) }}</span>
                                    </template>

                                    <template slot="top-row" v-if="isLoadMoreP2PAsk">
                                        <td aria-colindex="1" role="cell" class=""></td>
                                        <td aria-colindex="2" role="cell" class=""></td>
                                        <td aria-colindex="3" role="cell" class="text-right">
                                            <b-button class="btn-load-more mt-2" size="sm" @click="getPreviousP2PAsk(lastAskTokenId, limit)" v-if="isLoadMoreP2PAsk">See more <b-icon-arrow-up></b-icon-arrow-up> <b-spinner v-if="loadingP2PAsk" small label="Small Spinner"></b-spinner></b-button>
                                        </td>
                                    </template>

                                    <template #cell(action)="data">
                                        <b-button class="buy-now" size="sm" @click="buyNowData=data.item;$bvModal.show('buy-now')">Buy Now</b-button>
                                    </template>
                                </b-table>
                                <div class="current-price">
                                    <span class="price-active">{{ (latestPrice) ? utils.bigToLabel(latestPrice, quoteDecimals, 4) : '--' }}</span>
                                    <span class="price text-muted">--%</span>
                                </div>
                                <b-table id="global-bid-list" class="table-order" borderless :fields="bidFields" :items="bids" responsive  sticky-header="300px">
                                    <template #cell(price)="data">
                                        <span>{{ utils.bigToCommon(data.item.price, quoteDecimals, 4) }}</span>
                                    </template>
                                    <template #cell(amount)="data">
                                        <span>{{ utils.bigToCommon(data.item.amount, baseDecimals, 2) }}</span>
                                    </template>

                                    <template #cell(seller)="data">
                                        <router-link to="#">
                                            <span>{{ utils.truncate(data.item.seller, 7) }}</span>
                                        </router-link>
                                    </template>
                                    <template slot="bottom-row">

                                        <td aria-colindex="1" role="cell" class=""></td>
                                        <td aria-colindex="2" role="cell" class=""></td>
                                        <td aria-colindex="3" role="cell" class="text-right">
                                        <b-button class="btn-load-more mb-2" size="sm" @click="getNextP2PBid(lastP2PBidOrderId, limit)" v-if="isLoadMoreP2PBid">See more <b-icon-arrow-down></b-icon-arrow-down> <b-spinner v-if="loadingP2PBid" small label="Small Spinner"></b-spinner></b-button>
                                        </td>
                                    </template>

                                    <template #cell(action)="data">
                                        <b-button class="sell-now" size="sm" @click="sellNowData=data.item;$bvModal.show('sell-now')">Sell Now</b-button>
                                    </template>
                                </b-table>

                            </b-tab>
                        </b-tabs>
                        <div class="orders-bottom"><b-button class="btn-global-bid" @click="$bvModal.show('p2p-bid')">&nbsp; Bid &nbsp;</b-button>
                            <b-button class="btn-p2p-ask" @click="$bvModal.show('p2p-ask')">&nbsp; Ask &nbsp;</b-button></div>
                    </div>
                </div>
                <div class="history-content">
                    <div class="history-left mb-4" >
                        <b-tabs lazy>
                            <b-tab :title="`P2P-SELL (${iP2PAsk})`">
                                <div>
                                    <b-table class="table-order" :fields="userP2PAskFields" :items="userP2PAsks" responsive show-empty>
                                        <template #cell(amount)="data">
                                            <span>{{ utils.bigToCommon(data.item.amount, baseDecimals, 2) }}</span>
                                        </template>

                                        <template #cell(price)="data">
                                            <span>{{ utils.bigToCommon(data.item.price, quoteDecimals, 4) }}</span>
                                        </template>

                                        <template #cell(action)="data">
                                            <b-button class="remove-ask" size="sm" @click="removeAsk(data.item.orderId)"><b-icon-trash></b-icon-trash></b-button>
                                        </template>
                                        <template #empty="">
                                            <div class="no-item" v-if="userP2PAsks.length === 0">
                                                <b-card-img class="nologo" :src="require('../../assets/images/nologo.svg')" alt="Image"></b-card-img>
                                                <b-card-text>No record found.</b-card-text>
                                            </div>
                                        </template>
                                    </b-table>
                                    <div class="mt-3 pb-4" v-if="iP2PAsk > limit / 2">
                                        <b-pagination v-model="currentPageAsk" :per-page="limit / 2" @input="loadP2PAsks((currentPageAsk - 1) * limit / 2, currentPageAsk * limit / 2)" :total-rows="iP2PAsk" align="center"></b-pagination>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab :title="`P2P-BUY (${iP2PBid})`">
                                <div>
                                    <b-table class="table-order" :fields="userP2PBidFields" :items="userP2PBids" responsive show-empty>

                                        <!-- A custom formatted column -->
                                        <template #cell(pair)="data">
                                            <div class="i-flex s-gap">
                                                <b-img :src="data.item.image" width="20" />
                                                    <span>{{ data.item.name }}</span>
                                            </div>
                                        </template>

                                        <template #cell(price)="data">
                                            <span>{{ utils.bigToLabel(data.item.price, quoteDecimals, 4) }}</span>
                                        </template>

                                        <template #cell(amount)="data">
                                            <span>{{ utils.bigToLabel(data.item.amount, baseDecimals, 2) }}</span>
                                        </template>

                                        <template #cell(action)="data">
                                            <b-button class="remove-gb" size="sm" @click="removeP2PBid(data.item.orderId)"><b-icon-trash></b-icon-trash></b-button>
                                        </template>

                                        <template #empty="">
                                            <div class="no-item" v-if="userP2PBids.length === 0">
                                                <b-card-img class="nologo" :src="require('../../assets/images/nologo.svg')" alt="Image"></b-card-img>
                                                <b-card-text>No record found.</b-card-text>
                                            </div>
                                        </template>
                                    </b-table>

                                    <div class="mt-3 pb-4" v-if="iP2PBid > limit / 2">
                                        <b-pagination v-model="currentPageGlobalBid" :per-page="limit / 2" @input="loadP2PBids((currentPageGlobalBid - 1) * limit / 2, currentPageGlobalBid * limit / 2)" :total-rows="iP2PBid" align="center"></b-pagination>
                                    </div>

                                </div>

                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <b-icon-gear></b-icon-gear>
                                </template>
                                <div class="setting-content mt-3 ml-3 mr-3">
                                    <MMSetting :quoteLink="quoteLink" :baseLink="baseLink"
                                    @update-success="(data) => { handleTxConfirmed(data) }" />
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <b-icon-cash></b-icon-cash>
                                </template>
                                <div>
                                    <Earn :quoteDecimals="quoteDecimals" :quoteSymbol="quoteSymbol"
                                    @enable-referral-success="(data) => { handleTxConfirmed(data) }"
                                    @apply-success="(data) => { handleTxConfirmed(data) }"
                                    @claim-success="(data) => { handleTxConfirmed(data) }"
                                    @earn-success="(data) => { handleTxConfirmed(data) }"
                                    @create-code-success="(data) => { handleTxConfirmed(data) }"
                                    @enable-mining-success="(data) => { handleTxConfirmed(data) }"/>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </b-container>
        <b-modal id="import-token-alert" no-close-on-backdrop centered hide-footer title="Import tokens">
            <ImportTokenAlert @import-success="$bvModal.hide('import-token-alert')" :tokens="{ base: base, quote: quote }" />
        </b-modal>

        <b-modal id="p2p-bid" scrollable centered hide-footer title="P2P BID">
            <P2PBid :bidData="bidData" :quoteLink="quoteLink" :baseLink="baseLink" @p2p-bid-success="(data) => { handleTxConfirmed(data); handleP2PBidSuccess(); $bvModal.hide('p2p-bid') }" @p2p-bid-cancel="$bvModal.hide('p2p-bid')" />
        </b-modal>
        <b-modal id="p2p-ask" scrollable centered hide-footer title="P2P ASK">
            <P2PAsk :askData="askData" :quoteLink="quoteLink" :baseLink="baseLink" @p2p-ask-success="(data) => { handleTxConfirmed(data); handleP2PAskSuccess(); $bvModal.hide('p2p-ask') }" @p2p-bid-cancel="$bvModal.hide('p2p-ask')" />
        </b-modal>
        <b-modal id="buy-now" no-close-on-backdrop centered hide-footer title="P2P BUY-NOW">
            <P2PBuy :quoteLink="quoteLink" :baseLink="baseLink" :askData="buyNowData" @buy-success="((data) => { getPreviousP2PAsk(0, limit).then(() => { $bvModal.hide('buy-now'); handleTxConfirmed(data); handleBuySuccess() })})" @buy-cancel="$bvModal.hide('buy-now')" />
        </b-modal>
        <b-modal id="sell-now" no-close-on-backdrop centered hide-footer title="P2P SELL-NOW">
            <P2PSell :quoteLink="quoteLink" :baseLink="baseLink" :bidData="sellNowData" @sell-success="((data) => { handleSellSuccess(); $bvModal.hide('sell-now'); handleTxConfirmed(data); })" @sell-cancel="$bvModal.hide('sell-now')" />
        </b-modal>

        <b-toast id="tx-confirm" toaster="b-toaster-top-center" title="Transaction Process" variant="info" solid no-auto-hide>
            Your transaction is being processed.
        </b-toast>
        <b-toast id="tx-success" toaster="b-toaster-top-center" title="Transaction Success" variant="success" solid auto-hide-delay="8000">
            Your transaction is successful. <b-link v-if="txHash" class="view-explorer" target="_blank" :href="utils.txLink(chainId, txHash)"><b-icon icon="box-arrow-up-right"></b-icon> View Transaction</b-link>
        </b-toast>
        <b-toast id="tx-error" toaster="b-toaster-top-center" title="Transaction Failed" variant="danger" solid auto-hide-delay="8000">
            Your transaction was not successfully broadcasted.
        </b-toast>
        <Footer :isActiveData="isActiveData" />
    </div>

</template>
<script>
import { ethers } from 'ethers'
import { FTMarket, FTMarketMulticall } from '@bazarion/sdk'
import store from '../../stores'
import ImportTokenAlert from '../ImportTokenAlert.vue'
import P2PBid from './P2PBid.vue'
import P2PAsk from './P2PAsk.vue'
import P2PBuy from './P2PBuy.vue'
import P2PSell from './P2PSell.vue'
import Earn from './Earn.vue'
import MMSetting from './MMSetting.vue'
import Footer from '../Footer.vue'

export default {
    components: {
        ImportTokenAlert,
        P2PBid,
        P2PAsk,
        P2PBuy,
        P2PSell,
        Earn,
        MMSetting,
        Footer
    },
    data: () => ({
        window: {
            width: 0,
            height: 0
        },
        collectionFields: [
            'tokenId',
            { key: 'name', stickyColumn: true},
            'bidPrice',
            'askPrice',
            'owner'
        ],
        askFields: [
            'price',
            'amount',
            {
                key: 'action',
                label: '',
                tdClass: 'text-right',
                thClass: 'text-right'
            }

        ],
        bidFields: [
            'price',
            'amount',
            {
                key: 'action',
                label: '',
                tdClass: 'text-right',
                thClass: 'text-right'
            },
        ],
        orderFields: [
            'item',
            'price',
            'buyer',

        ],
        sellFields: [
            {
                key: 'price',
                tdClass: 'sell-price',
            },
            {
                key: 'amount',
                tdClass: 'text-center',
                thClass: 'text-center'
            },
            {
                key: 'total',
                tdClass: 'text-right',
                thClass: 'text-right'
            },
        ],
        buyFields: [
            {
                key: 'price',
                tdClass: 'buy-price'
            },
            {
                key: 'amount',
                tdClass: 'text-center',
                thClass: 'text-center'
            },
            {
                key: 'total',
                tdClass: 'text-right',
                thClass: 'text-right'
            },
        ],
        baseFields: [
            { key: 'tokenId', label: 'ID' },
            'name',
            {
                key: 'action',
                label: '',
                tdClass: 'text-right',
                thClass: 'text-right'
            },
        ],
        userP2PAskFields: [
            { key: 'orderId', label: 'ORDER ID' },
            'amount',
            'price',
            {
                key: 'action',
                label: '',
                tdClass: 'text-right',
                thClass: 'text-right'
            },
        ],
        userP2PBidFields: [
            { key: 'orderId', label: 'Order ID' },
            'amount',
            'price',
            {
                key: 'action',
                label: '',
                tdClass: 'text-right',
                thClass: 'text-right'
            },
        ],
        userBidFields: [
            { key: 'tokenId', label: 'ID' },
            'name',
            {
                key: 'action',
                label: '',
                tdClass: 'text-right',
                thClass: 'text-right'
            },
        ],
        openOrderFields: [
            {
                key: 'time',
                label: 'Date'
            },
            'pair',
            'type',
            'side',
            'price',
            'amount',
            'filled',
            'total',
        ],
        // End data demo
        selected: 'ID',
        options: [
            { value: 'ID', text: 'Sort Default' },
            { value: 'PL2H', text: 'Price: Low to High' },
            { value: 'PH2L', text: 'Price: High to Low' },
            { value: 'OL2H', text: 'Offer: Low to High' },
            { value: 'OH2L', text: 'Offer: High to Low' },
        ],
        currencySelected: 'BAZA',
        currencyOptions: [
            { value: 'BAZA', text: 'Currency: BAZA' },
        ],
        base: '',
        quote: '',
        quoteLink: '',
        baseLink: '',
        baseSymbol: '',
        quoteSymbol: '',
        baseName: '',
        tokenId: 0,
        tokenName: '',
        market: {},
        baseContract: {},
        tokenNfts: [],
        tokenFts: [],
        isCreate: false,
        totalSupply: 0,
        cSupply: 0,
        exchangeFee: 0,
        creatorFee: 0,
        bestBidMarketPrice: ethers.BigNumber.from(0),
        tvl: 0,
        roi: 0,
        items: [],
        quoteDecimals: 18,
        page: 1,
        limit: 16,
        loadingItems: true,
        loadingData: true,
        isQuoteNative: false,
        isBaseNative: false,
        stats: {},
        allData: [],
        result: [],
        askPrices: {},
        bidPrices: {},
        search: '',
        selects: [],
        baseInfo: {},
        isAddClass1: false,
        isAddClass2: true,
        classActive: 'columns-4',
        totalResults: 0,
        isPixelated: false,
        isConnect: false,
        lpPrice: ethers.BigNumber.from(0),
        lpShare: ethers.BigNumber.from(0),
        lpROI: 0,
        lpIsAuto: false,
        floorPrice: ethers.BigNumber.from(0),
        latestPrice: ethers.BigNumber.from(0),
        bestAskPrice: ethers.BigNumber.from(0),
        bestBidPrice: ethers.BigNumber.from(0),
        totalVolume: ethers.BigNumber.from(0),
        volume24h: ethers.BigNumber.from(0),
        isMintBox: false,
        isMintBoxToast: false,
        statusOptions: [
            { text: 'Only Buy Now', value: 1 },
            { text: 'Show All', value: 0 }
        ],
        isLoadMore: false,
        asks: [],
        bids: [],
        txHash: '',
        bidData: {},
        askData: {},
        iP2PAsk: 0,
        iP2PBid: 0,
        userP2PAsks: [],
        userBids: [],
        userNfts: [],
        userP2PBids: [],
        sellNowData: {},
        buyNowData: {},
        currentPage: 1,
        currentPageNFT: 1,
        currentPageAsk: 1,
        currentPageGlobalBid: 1,
        lastP2PBidOrderId: 0,
        loadingP2PBid: false,
        isLoadMoreP2PBid: false,
        lastAskTokenId: 0,
        isLoadMoreP2PAsk: false,
        loadingP2PAsk: false,
        isActiveData: true
    }),
    updated: async function () {
        this.$nextTick().then(() => {
            setTimeout(() => {
                let baseImg = document.querySelector(".base-image")
                if (baseImg) {
                    if (baseImg.naturalWidth < 200) {
                        this.isPixelated = true
                    } else {
                        this.isPixelated = false
                    }
                }
            }, 5000)
        })

        let objDiv = document.getElementsByClassName('tbl-ask-order')
        if (objDiv.length > 0) {
            objDiv[0].scrollTop = objDiv[0].scrollHeight
        }

    },
    created: async function () {
        let base = this.$route.params.base
        let quote = this.$route.params.quote
        this.quoteLink = quote
        this.baseLink = base

        try {
            this.provider = await this.getProvider(parseInt(this.$route.params.chainId))

            this.isConnect = (this.provider.connection.url !== 'metamask')
            this.address = await this.provider.getSigner().getAddress()
        } catch (e) {
            this.isConnect = true
        }

        this.provider = await this.getProvider(parseInt(this.$route.params.chainId))
        this.market = await FTMarket.setMarket(base, quote, this.provider)

        this.base = ethers.utils.getAddress(this.market.baseToken)
        this.quote = ethers.utils.getAddress(this.market.quoteToken)
        this.isQuoteNative = this.market.isQuoteNative
        this.isBaseNative = this.market.isBaseNative
        this.marketAddress = this.market.marketAddress

        let tokens = store.getTokens()
        this.baseInfo = tokens.find(t => (t.address === this.base && t.chainId === this.chainId)) || {}

        store.setUIMode('pro')

        await this.getMarketInfo()

        let b = this.utils.checkFTTokenAlert(this.chainId, this.base) || this.utils.checkFTTokenAlert(this.chainId, this.quote)
        if (b) {
            this.$bvModal.show('import-token-alert')
        }

        this.loadingData = false
        this.loadingItems = false

        this.getNextP2PBid(0, this.limit)
        this.getPreviousP2PAsk(0, this.limit)

        if (this.address) {
            this.iP2PAsk = (await this.market.askBalanceOf(this.address)).toNumber()
            this.iP2PBid = (await this.market.bidBalanceOf(this.address)).toNumber()

            this.loadP2PAsks(0, this.limit / 2)
            this.loadP2PBids(0, this.limit / 2)
        }

        window.addEventListener('resize', this.handleResize)
        this.handleResize()

    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth
            this.window.height = window.innerHeight
        },
        getPreviousP2PAsk: async function (orderId, limit) {
            if (orderId === 0) {
                this.asks = []
            }
            this.loadingP2PAsk = true
            let asks = await this.market.getPreviousAsk(orderId, limit)
            for (let i = 0; i < limit; i++) {
                if (asks[i].orderId.eq(0)) {
                    this.isLoadMoreP2PAsk = false
                    break
                }
                this.lastAskTokenId = asks[i].orderId
                this.asks.push({
                    orderId: asks[i].orderId,
                    price: asks[i].price,
                    amount: asks[i].amount,
                    action: ''
                })
                this.isLoadMoreP2PAsk = true
            }
            this.asks.sort((a, b) => {
                if (a.price.lt(b.price)) return 1
                if (a.price.gt(b.price)) return -1
                return 0
            })
            this.loadingP2PAsk = false
        },
        getNextP2PBid: async function (orderId, limit) {
            if (orderId === 0) {
                this.bids = []
            }
            this.loadingP2PBid = true
            let bids = await this.market.getNextBid(orderId, limit)
            for (let i = 0; i < limit; i++) {
                if (bids[i].orderId.eq(0)) {
                    this.isLoadMoreP2PBid = false
                    break
                }
                this.lastP2PBidOrderId = bids[i].orderId,
                this.bids.push({
                    orderId: this.lastP2PBidOrderId,
                    price: bids[i].price,
                    amount: bids[i].amount,
                    action: ''
                })
                this.loadingP2PBid = true
            }
            this.loadingP2PBid = false
        },
        reloadP2PBids: async function (from, to) {
            this.iP2PBid = (await this.market.bidBalanceOf(this.address)).toNumber()
            await this.loadP2PBids(from, to)
        },
        reloadP2PAsks: async function (from, to) {
            this.iP2PAsk = (await this.market.askBalanceOf(this.address)).toNumber()
            await this.loadP2PAsks(from, to)
        },
        loadP2PBids: async function (from, to) {
            if (from === 0) this.userP2PBids= []
            to = (to > this.iP2PBid) ? this.iP2PBid : to
            let calls = await FTMarketMulticall.setMulticall(this.base, this.quote, this.provider)
            let bids = await calls.bidOfOwnerByIndex(this.address, from, to)
            let data = await calls.getBidOrderInfo(bids)
            this.userP2PBids = data
        },
        loadP2PAsks: async function (from, to) {
            if (from === 0) this.userP2PAsks = []
            to = (to > this.iP2PAsk) ? this.iP2PAsk : to
            let calls = await FTMarketMulticall.setMulticall(this.base, this.quote, this.provider)
            let asks = await calls.askOfOwnerByIndex(this.address, from, to)
            let userP2PAsks = await calls.getAskOrderInfo(asks)
            this.userP2PAsks = userP2PAsks
        },
        handleTxConfirmed: async function(data) {
            this.txHash = data.txHash
        },
        handleP2PBidSuccess: async function() {
            this.iP2PBid = this.iP2PBid + 1
            await this.getNextP2PBid(0, this.limit)
        },
        handleP2PAskSuccess: async function() {
            this.iP2PAsk = this.iP2PAsk + 1
            await this.getPreviousP2PAsk(0, this.limit)
        },
        handleBuySuccess: async function() {
            await this.getNextP2PBid(0, this.limit)
            await this.getPreviousP2PAsk(0, this.limit)
            await this.getMarketInfo()
        },
        handleSellSuccess: async function() {
            await this.getNextP2PBid(0, this.limit)
            await this.getPreviousP2PAsk(0, this.limit)
            await this.getMarketInfo() 
        },
        async removeAsk(orderId) {
            let tx = await this.market.ask(orderId, ethers.BigNumber.from(0), ethers.BigNumber.from(1))
            this.$bvToast.show('tx-confirm')
            await tx.wait()
            this.txHash = tx.hash
            this.$bvToast.show('tx-success')
            this.$bvToast.hide('tx-confirm')
            this.iP2PAsk = this.iP2PAsk - 1
            await this.reloadP2PAsks(0, this.limit / 2)
            await this.getPreviousP2PAsk(0, this.limit)
        },
        async removeP2PBid(orderId) {
            let tx = await this.market.bid(orderId, ethers.BigNumber.from(0), ethers.BigNumber.from(1))
            this.$bvToast.show('tx-confirm')
            await tx.wait()
            this.txHash = tx.hash
            this.$bvToast.show('tx-success')
            this.$bvToast.hide('tx-confirm')
            this.iP2PBid = this.iP2PBid - 1
            await this.reloadP2PBids(0, this.limit)
            await this.getNextP2PBid(0, this.limit)
        },
        async getMarketInfo() {
            let calls = await FTMarketMulticall.setMulticall(this.base, this.quote, this.provider)
            let { baseSupply, quoteSupply, baseTvl, quoteTvl, baseDecimals, quoteDecimals,
                bestBid, bestAsk, latestPrice, exchangeFee, quoteSymbol, baseSymbol, baseName } = await calls.getMarketInfo()
            this.baseSupply = baseSupply
            this.quoteSupply = quoteSupply
            this.baseSymbol = baseSymbol
            this.baseName = baseName
            this.quoteSymbol = quoteSymbol
            this.bestBidPrice = bestBid.price
            this.bestAskPrice = bestAsk.price
            this.baseTvl = baseTvl
            this.quoteTvl = quoteTvl
            this.baseDecimals = baseDecimals
            this.quoteDecimals = quoteDecimals
            this.latestPrice = latestPrice
            this.exchangeFee = exchangeFee
        }
    }
};
</script>
<style lang="scss" >
$primary-color: #FEBC11;

.base-items {
    padding: 0;
    margin: 0;
    list-style: none;

    &__wrapper {
        padding: 16px;
        background-color: #F4F5F7;
        border-radius: 8px;
        justify-content: space-between;
        margin-bottom: 8px;
        .t-size {
            font-size: 10px;
            margin-bottom: 4px;
        }
        .s-gap {
            font-size: 12px;
            img {
                width: 12px;
                height: auto;
            }
        }
    }

    &__img {
        width: 24px;
        height: auto;
    }

    .base-items__cancel {
        border: 0;
        color: #DE350B;
        background-color: #FFEBE6;
        border-radius: 100px;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
    }

    .base-items__toggle {
        background-color: transparent;
        color: #091E42;
        font-size: 8px;
        border: 0;
        margin-right: -8px;
        &:hover,
        &:focus,
        &:active,
        &.btn-secondary:not(:disabled):not(.disabled):active {
            background-color: transparent;
            color: $primary-color;
            border: 0;
            box-shadow: none;
        }
    }
}

.ft-trade-wrap {
    height: calc(100vh - 80px);
    background-color: #fff;

    .container {
        max-width: 600px;
    }

    @media(max-width: 991px) {
        min-height: 100vh;
        height: calc(100% - 60px);
        padding-top: 16px !important;
        padding-bottom: 60px !important;
    }

    .table-detail {
        width: calc(100% - 120px);
        thead {
            tr {
                border-bottom: 1px solid #EBECF0;
            }
        }
    }

    .collection-table {
        thead {
            tr {
                border-bottom: 1px solid #EBECF0;
            }
        }
    }

    .space-between {
        justify-content: space-between;
        align-items: center;
    }
    .c-header{
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid #DFE1E6;

        @media(max-width: 991px) {
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
            padding: 0 15px;
            border-bottom: 0;
        }
        .collection-name{
            .market-links a{
                color: #FEBC11;
            }
            .box-name{
                padding-top: 16px;
                padding-bottom: 16px;
                gap: 15px;
                align-items: center;
                .name{
                    margin-bottom: 0;
                }

                @media(max-width: 991px) {
                    gap: 0;
                    padding: 0;
                    > img {
                        width: 32px;
                        height: auto;
                        margin-right: 8px;
                    }
                    .social-link {
                        img {
                            width: 12px;
                            height: auto;
                        }
                    }
                    .name {
                        font-size: 14px;
                        img {
                            width: 12.68px;
                            height: auto;
                        }
                    }
                }
            }
            img{
                width: 48px;
                border-radius: 50%;
            }
        }
        .collection-info{
            .box-info{
                gap: 20px;
            }
            @media(max-width: 991px) {
                width: 100%;

                .box-info{
                    gap: 0;
                }
            }
            @media(max-width: 767px) {
                .box-info{
                    gap: 0;
                }
            }
        }
    }
    .social-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            display: inline-block;
            margin-left: 10px;

            a {
                display: block;

                &:hover {

                    .network,
                    .telegram {
                        path {
                            fill: #9D22C1;
                        }
                    }

                    .twitter {
                        circle {
                            fill: #9D22C1;
                        }
                    }
                }
            }
        }

        #my-base {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 8px;
            color: #fff;
            background-color: #9D22C1;

            &:hover {
                background-color: #b62adf;
            }
        }
    }

    .card-item {
        border-radius: 8px;
        min-height: 76px;
        justify-content: center;
        border: none;

        @media(max-width: 991px) {
            width: calc(25% - 10px);
            text-align: left !important;
            min-height: auto;
        }

        @media(max-width: 767px) {
            width: calc(33.33%);
        }
    }

    .t-size {
        font-size: 12px;
        color: #7A869A;
        text-transform: uppercase;
    }

    .about {
        &:after {
            content: '';
            width: calc(100% - 30px);
            height: 1px;
            background-color: #DFE1E6;
            margin: 20px auto 20px auto;
        }
    }
    .content-left{
        display: flex;
        flex-direction: column;
        width: 238px;
        border-right: 1px solid #DFE1E6;
        border-left: 1px solid #DFE1E6;
        max-height: 746px;
        position: relative;
        .attr-body{
            height: 100%;
            position: relative;
            overflow-y: auto;
        }
    }
    .collection-content{
        display: flex;
        width: 100%;
        border-bottom: 1px solid #DFE1E6;

        @media(max-width: 991px) {
            display: block;
        }
        .content-list-base{
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(100% - 586px);

            @media(max-width: 991px) {
                width: 100%;
            }
            .t-action{
                cursor: pointer;
            }
            .table{
                thead{
                    th{
                        border-top: none;
                    }
                }
            }
            .tabs {
                height: 100%;
            }
            .list-bottom{
                display: flex;
                justify-content: space-between;
                padding: 0px 10px;
                align-self: flex-end;
                height: 70px;
                width: 100%;
                .total-results {
                    padding: 24px 0px;
                }
                .btn-load-more {
                    &:focus {
                        box-shadow: none;
                    }
                    background-color: white;
                    border-color: white;
                    color: #36B37E;
                    height: 100%;
                }
            }
        }

        .content-right{
            display: flex;
            flex-direction: column;
            width: 100%;
            border-left: 1px solid #DFE1E6;
            border-right: 1px solid #DFE1E6;

            .btn-load-more {
                padding: 0px;
                font-size: 12px;
                &:focus {
                    box-shadow: none;
                    background-color: white;
                    border-color: white;
                }
                background-color: white;
                border-color: white;
                color: #36B37E;
                height: 100%;
            }

            @media(max-width: 991px) {
                width: calc(100% + 30px);
                max-width: calc(100% + 30px);
                margin-left: -15px;
                border: 0;
                padding: 0 15px;
                .table  {
                    th,
                    td {
                        &:first-child {
                            padding-left: 0;
                            text-align: left;
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                        text-align: center;
                    }
                }

                .current-price {
                    padding-left: 0 !important;
                }
            }

            .tabs {
                height: 100%;
            }
            .orders-bottom{
                align-self: flex-end;
                width: 100%;
                height: 70px;
                padding: 16px 24px;
                .btn-global-bid {
                    background: #36B37E;
                    color: #FFF;
                    border-radius: 30px;
                    border-color: #36B37E;
                    &:focus {
                        box-shadow: none;
                    }
                    &:hover {
                        box-shadow: none;
                        color: #36B37E;
                        background-color: #FFF;
                    }
                }
                .btn-p2p-ask {
                    float: right;
                    background: #DE350B;
                    color: #FFF;
                    border-radius: 30px;
                    border-color: #DE350B;
                    &:focus {
                        box-shadow: none;
                    }
                    &:hover {
                        box-shadow: none;
                        color: #DE350B;
                        background-color: #FFF;
                    }
                }
            }

            .buy-now{
                font-size: 10px;
                padding: 0px 5px;
                background-color: #E3FCEF;
                border-color: #36B37E;
                color: #36B37E;
                border-radius: 10px;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    box-shadow: none;
                    color: #FFF;
                    background-color: #36B37E;
                }
            }
            .sell-now{
                border-color: #DE350B;
                background-color: #FFEBE6;
                color: #DE350B;
                font-size: 10px;
                padding: 0px 5px;
                border-radius: 10px;
                line-height: 16px;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    box-shadow: none;
                    color: #FFF;
                    background-color: #DE350B;
                }
            }

            .table{
                th{
                    padding: 8px 10px;
                    border-bottom: 1px solid #DFE1E6;
                }
                td {
                    padding: 5px 12px;
                }
            }
            .table-order{
                height: 292px;
                border-bottom: 1px solid #DFE1E6;
                margin-bottom: 0;
            }
            .sell-price{
                color: #DE350B;
            }
            .buy-price {
                color: #36B37E;
            }
            .current-price{        
                padding: 5px 12px;
                display: flex;
                gap: 10px;
                align-items: flex-end;
                font-size: 12px;
                .price-active{
                    color: #36B37E;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }
        }
    }
    .table {
        thead {
            color: #7A869A;
            font-size: 10px;
            text-transform: uppercase;

            th {
                font-weight: 500;
                border-bottom: none;
            }
        }

        tbody {
            font-size: 12px;
            color: #091E42;
        }
    }
    .nav-tabs{
        border-bottom: none;
        .nav-link{
            border-color: transparent!important;
            color: #7A869A;
            padding-top: 16px;
            padding-bottom: 16px;
            position: relative;
            &.active{
                color: #091E42;
                font-weight: 600;
                &:before{
                    background-color: #FEBC11;
                }
            }
            &:before{
                content: "";
                width: 80%;
                height: 2px;
                position: absolute;
                left: 50%;
                bottom: 10px;
                transform: translateX(-50%);
            }
            @media(max-width: 991px) {
                padding: 15px 4px;
                margin-right: 16px;
                font-size: 12px;

                &::before {
                    width: 100%;
                }
            }
        }
    }
    .history-content{
        border-bottom: 1px solid #DFE1E6;
        border-right: 1px solid #DFE1E6;
        border-left: 1px solid #DFE1E6;
        display: flex;

        @media(max-width: 991px) {
            display: block;
            border-bottom: 0;
        }

        .table {
            thead {
                th {
                    border-top: none;
                }
            }
        }
        .reward-content{
            width: 238px;
            border-left: 1px solid #DFE1E6;
            border-right: 1px solid #DFE1E6;
            display: flex;
            flex-direction: column;
            @media(max-width: 991px) {
                border: 0;
                width: 100%;
            }
        }

        .history-left{
            display: flex;
            flex-direction: column;
            width: calc(100%);

            @media(max-width: 991px) {
                width: 100%;
            }

            .remove-gb{
                border-color: #FFF;
                padding: 0px 5px;
                font-size: 10px;
                background-color: #FFF;
                color: #DE350B;
                border-radius: 10px;
                line-height: 16px;
                white-space: nowrap;
            }
            .remove-ask{
                border-color: #FFF;
                padding: 0px 5px;
                font-size: 10px;
                background-color: #FFF;
                color: #DE350B;
                border-radius: 10px;
                line-height: 16px;
                white-space: nowrap;
            }
        }

        .history-right{
            display: flex;
            flex-direction: column;
            max-width: 360px;
            width: 100%;
            min-height: 550px;
            border-left: 1px solid #DFE1E6;
            border-right: 1px solid #DFE1E6;

            @media(max-width: 991px) {
                width: 100%;
                max-width: 100%;
                display: block;
                border: 0;
                min-height: 150px;
            }
            
            .table td{
                border-top: 1px solid #FFF;
            }
            .ask-now{
                border-color: #E3FCEF;
                padding: 0px 5px;
                font-size: 10px;
                background-color: #E3FCEF;
                color: #36B37E;
                border-radius: 10px;
                line-height: 16px;
                white-space: nowrap;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    box-shadow: none;
                    color: #FFF;
                    background-color: #36B37E;
                }
            }
        }

        @media(max-width: 991px) {
            border: 0;
        }
    }




    // Old css
    .desc {
        font-weight: 400;
    }


    .result {
        padding: 4px 16px;
        border-radius: 16px;
        background-color: #9D22C1;
        font-size: 12px;
        margin-bottom: 5px;
        color: #fff;
    }

    .no-item {
        text-align: center;
        width: 100%;
        display: block;
        color: #5E6C84;
        margin-top: 30px;
        margin-bottom: 60px;

        img {
            max-width: 80px;
        }
    }

    .base-item {

        margin-bottom: 24px;
        padding: 16px;
        border-radius: 8px;
        background-color: #F4F5F7;
        border-color: transparent;
        transition: all 0.35s ease;
        cursor: pointer;

        &:hover {
            background-color: #fff;
            box-shadow: 0px 0px 8px 2px #e9e9e9;
        }

        >a {
            color: inherit;

            &:hover {
                text-decoration: none;
            }
        }

        .card-body {
            padding: 16px 0 0 0;
        }

        .is-flex {
            justify-content: space-between;
        }

        .base-head {
            width: 61%;
        }

        .base-price {
            width: 39%;
        }

        .base-acc {
            font-size: 12px;
            margin-bottom: 0;
            color: #FEBC11;
            line-height: 16px;
        }

        .base-title {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 0;
        }

        .base-price-text {
            font-size: 12px;
            margin-bottom: 0;
            color: #7A869A;
            text-align: right;
        }

        .base-price-value {
            font-size: 20px;
            text-align: right;
        }
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        border-color: #FEBC11;
        background-color: #FEBC11;
    }
}

.columns-4 {
    .base-item {
        width: calc(25% - 20px);
        margin-right: 24px;

        @media(min-width: 768px) {
            &:nth-child(4n) {
                margin-right: 0;
            }
        }

        @media(max-width: 767px) {
            width: calc(50% - 14px);
            margin-right: 24px;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @media(max-width: 560px) {
            width: 100%;
            margin-right: 0;
        }
    }
}

.columns-3 {
    .base-item {
        width: calc(33.33% - 20px);
        margin-right: 24px;

        @media(min-width: 768px) {
            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        @media(max-width: 767px) {
            width: calc(50% - 14px);
            margin-right: 24px;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @media(max-width: 560px) {
            width: 100%;
            margin-right: 0;
        }
    }
}

#import-token-alert {
    .modal-dialog {
        max-width: 520px;
    }

    .modal-content {
        border-radius: 16px;
        overflow: hidden;
    }

    .modal-header {
        background: linear-gradient(243.18deg, #f4d4fd 0%, #ffffff 100%);
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 16px;
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 15px;
    }
}

#your-base {
    .modal-content {
        border-radius: 16px;
        overflow: hidden;
    }

    .modal-header {
        background: linear-gradient(243.18deg, #f4d4fd 0%, #ffffff 100%);
        justify-content: center;

        .modal-title {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 15px;
    }
}

#base-settings {
    .dropdown button {
        padding-right: 0px;
    }

    .dropdown ul li {
        margin-left: 0px;
        width: 100%;
    }
}

#market-info {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 8px;
    color: #fff;
    background-color: #9D22C1;
}

.collection-info {
    .navbar {
        padding: 0px;
    }

    .navbar-light .navbar-nav .nav-link,
    .navbar-light .navbar-nav .nav-link:focus {
        color: #9D22C1;
    }

    @media(max-width: 991px) {
        margin-bottom: 12px;
    }
    @media(max-width: 888px) {
        .navbar-nav.ml-auto {
            display: block !important;
        }

        #nav-collapse {
            display: block !important;
        }
    }
}

.color-sale {
    color: #DE350B;
}

.color-buy {
    color: #36B37E;
}

.color-orange {
    color: #FF8B00;
}

.color-text {
    color: #091E42;

    &-light {
        color: #7A869A;
    }
}
.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}
</style>

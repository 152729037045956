import Home from './components/Home.vue'
import FAQ from './components/FAQ.vue'
import FTTrade from './components/FT/Trade.vue'

const routes = [
  { path: "/", component: Home },
  { path: "/faq", component: FAQ },
  { path: "/:chainId/faq", component: FAQ },
  { path: "/:chainId", component: Home, name: "home" },
  { path: "/:chainId/fttrade/:base/:quote", component: FTTrade, name: "fttrade" },
];

export default routes

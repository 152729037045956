const { ethers } = require('ethers')
const FTMarketAbi = require('@bazarion/protocol/abis/baza/FTMarket.json')
const FTFactoryAbi = require('@bazarion/protocol/abis/baza/FTFactory.json')
const BazaERC20Abi = require('@bazarion/protocol/abis/baza/BazaERC20.json')
const contracts = require('./contracts')
const { NETWORKS } = require('./constants')

class FTMarket {
    constructor(market, baseToken, quoteToken, isBaseNative, isQuoteNative, provider, network, address, signer = null) {
        if (!signer) {
            signer = provider.getSigner()
        }
        this.marketAddress = market
        this.baseToken = baseToken
        this.quoteToken = quoteToken
        this.isBaseNative = isBaseNative
        this.isQuoteNative = isQuoteNative
        this.provider = provider
        this.network = network
        this.address = address
        this.baseContract = new ethers.Contract(this.baseToken, BazaERC20Abi, signer)
        this.quoteContract = new ethers.Contract(this.quoteToken, BazaERC20Abi, signer)
        this.marketContract = new ethers.Contract(this.marketAddress, FTMarketAbi, signer)
        this.exchangeUnits = ethers.utils.parseUnits('1', 'ether')
    }

    static setMarket(baseToken, quoteToken, provider) {
        return provider.getNetwork().then(net => {
            const network = NETWORKS.find(n => (n.chainId === '0x' + net.chainId.toString(16))) || []
            const contract = contracts.find(c => (c.ChainId === net.chainId))

            let signer = provider.getSigner()
            if (provider.connection.url !== 'metamask') {
                let randomWallet = ethers.Wallet.createRandom()
                signer = new ethers.Wallet(randomWallet.privateKey, provider)
            }
            return signer.getAddress().then((address) => {
                return { signer: signer, address: address }
            }).catch(() => {
                let randomWallet = ethers.Wallet.createRandom()
                signer = new ethers.Wallet(randomWallet.privateKey, provider)
                return { signer: signer, address: randomWallet.address }
            }).then(({ signer, address }) => {
                let ftFactory = new ethers.Contract(contract.FTFactory, FTFactoryAbi, signer)
                let isQuoteNative = false
                let isBaseNative = false

                if (quoteToken.toUpperCase() === network.nativeCurrency.symbol) {
                    isQuoteNative = true
                    quoteToken = network.nativeCurrency.wrappedToken 
                }

                if (baseToken.toUpperCase() === network.nativeCurrency.symbol) {
                    isBaseNative = true
                    baseToken = network.nativeCurrency.wrappedToken 
                }

                return ftFactory.getMarket(baseToken, quoteToken).then((market) => {
                    return new FTMarket(market, baseToken, quoteToken, isBaseNative, isQuoteNative, provider, network, address, signer)
                })
            })
        })
    }

    static setMarketWithAddress(market, provider) {
        return provider.getNetwork().then(net => {
            const network = NETWORKS.find(n => (n.chainId === '0x' + net.chainId.toString(16))) || []
            const contract = contracts.find(c => c.ChainId = net.chainId)

            let signer = provider.getSigner()
            if (provider.connection.url !== 'metamask') {
                let randomWallet = ethers.Wallet.createRandom()
                signer = new ethers.Wallet(randomWallet.privateKey, provider)
            }
            return signer.getAddress().then((address) => {
                return { signer: signer, address: address }
            }).catch(() => {
                let randomWallet = ethers.Wallet.createRandom()
                signer = new ethers.Wallet(randomWallet.privateKey, provider)
                return { signer: signer, address: randomWallet.address }
            }).then(({ signer, address }) => {
                let marketContract = new ethers.Contract(market, FTMarketAbi, signer)
                let isQuoteNative = false
                return marketContract.baseToken().then(baseToken => {
                    return marketContract.quoteToken().then(quoteToken => {
                        if (quoteToken.toUpperCase() === network.nativeCurrency.symbol) {
                            isQuoteNative = true
                            quoteToken = network.nativeCurrency.wrappedToken 
                        }
                        return new FTMarket(market, baseToken, quoteToken, isQuoteNative, provider, network, address, signer)
                    })
                })

            })
        })
    }

    async bid(orderId, amount, price) {
        if (this.isQuoteNative) {
            let vol = amount.mul(price).div(this.exchangeUnits)
            return this.marketContract.bid([orderId], [amount], [price], {
                value: vol
            })
        } else {
            return this.marketContract.bid([orderId], [amount], [price])
        }
    }

    async ask(orderId, amount, price) {
        if (this.isBaseNative) {
            let vol = amount.mul(price).div(this.exchangeUnits)
            return this.marketContract.ask([orderId], [amount], [price], {
                value: vol
            })
        } else {
            return this.marketContract.ask([orderId], [amount], [price])
        }
    }
    
    async sell(orderId, amount, price) {
        let askPrice = await this.marketContract.getAskMMPrice(
            this.address,
            price
        )
        // TODO: limit, page
        let askLength = await this.marketContract.askBalanceOf(this.address)
        let askMMOrderId = await this.marketContract.getAskOrderIdByPrice(
            this.address,
            askPrice,
            0,
            askLength
        )

        if (this.isBaseNative) {
            let vol = amount.mul(price).div(this.exchangeUnits)
            return this.marketContract.sell([orderId], [amount], [price], [askMMOrderId], {
                value: vol
            })
        } else {
            return this.marketContract.sell([orderId], [amount], [price], [askMMOrderId])
        }
    }

    async buy(orderId, amount, price) {
        let bidPrice = await this.marketContract.getBidMMPrice(
            this.address,
            price
        )
        // TODO: limit, page
        let bidLength = await this.marketContract.bidBalanceOf(this.address)
        let bidMMOrderId = await this.marketContract.getBidOrderIdByPrice(
            this.address,
            bidPrice,
            0,
            bidLength
        )

        if (this.isQuoteNative) {
            let vol = amount.mul(price).div(this.exchangeUnits)
            return this.marketContract.buy([orderId], [amount], [price], [bidMMOrderId], {
                value: vol
            })
        } else {
            return this.marketContract.buy([orderId], [amount], [price], [bidMMOrderId])
        }
    }
    
    getMarketMakerAutoROI(addr) {
        return this.marketContract.getMarketMakerAutoROI(addr)
    }

    setMarketMakerConfig(roi) {
        return this.marketContract.setMarketMakerConfig(roi)
    }

    getNextBid(orderId, limit) {
        return this.marketContract.getNextBid(orderId, limit)
    }

    getPreviousAsk(orderId, limit) {
        return this.marketContract.getPreviousAsk(orderId, limit)
    }

    askBalanceOf(addr) {
        return this.marketContract.askBalanceOf(addr)
    }

    bidBalanceOf(addr) {
        return this.marketContract.bidBalanceOf(addr)
    }
}

module.exports = FTMarket
